import { Grid, Paper, Box, TextField } from "@mui/material";
import { LinearProgress, Button, InputAdornment } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReadOnlyTextField from "../../ui/ReadOnlyTextField";
import { store } from "../../../store";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { getOrder } from "../../../redux/actions/order";
import OrderTable from "../../ui/OrderTable";
import { createShipment } from "../../../services/shipmentService";
import UserInfo from "../../ui/UserInfo";
import { fetchOrder, updateOrderStatus } from "../../../services/orderService";
import { updateProductionStatus } from "../../../services/productionService";
import { showErrorAlert, showSuccessAlert } from "../../ui/utils/AlertUtils";

export const ScreenConfirmShippingForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const newShipment = location.state?.newShipment;
  const user = useSelector((state) => state.user.user);
  const order = useSelector((state) => state.order.order);
  const centerId = location.state?.centerId;
  const studyId = location.state?.studyId;
  const orderId = location.state?.orderId;
  const [isLoading, setIsLoading] = React.useState(true);
  const centerNumber = location.state?.centerNumber;
  const studyCode = location.state?.studyCode;
  const addressParts = [
    order?.hospital,
    order?.ward,
    order?.building ? `Building: ${order.building}` : null,
    order?.street,
    order?.floor ? `Floor: ${order.floor}` : null,
    order?.zipcode,
    order?.shippingAddress,
  ];
  const filteredAddress = addressParts
    .filter((part) => part && part.trim() !== "")
    .join(", ");

  const editShipment = () => {
    navigate("/shipping/new", {
      state: {
        orderId,
        newShipment,
        centerNumber,
        studyCode,
        centerId,
        studyId,
      },
    });
  };

  const handleCreateShipment = async () => {
    // function to update the package details string
    try {
      let details = `${newShipment?.numPackages} nb of pkg, `;
      newShipment?.packages.forEach((pkg, index) => {
        details += `pkg ${index + 1}: ${pkg.length} l, ${pkg.height} h, ${
          pkg.width
        } w, ${pkg.volumetricWeight} vw; `;
      });

      // Create the shipment
      const shipmentResponse = await createShipment(
        orderId,
        newShipment,
        details,
        user
      );

      // Get the created shipment's id
      const shipmentId = shipmentResponse.id;

      // Associate the productions to the shipment
      // Update the status and shipmentId of each item in productionsSent
      for (const key in newShipment.productionsSent) {
        const productionsArray = newShipment.productionsSent[key];
        for (const production of productionsArray) {
          await updateProductionStatus(
            production.id,
            "in transit",
            order?.centerId,
            shipmentId
          );
        }
      }

      // Fetch the order and its shipments
      const orderWithShipments = await fetchOrder(orderId);

      // Calculate the total shipped quantity
      let totalShippedQuantity = 0;
      for (const shipment of orderWithShipments.shipments) {
        totalShippedQuantity += shipment.shippedQuantity;
      }

      // Determine the new order status
      let newOrderStatus;
      if (totalShippedQuantity >= orderWithShipments.totalQuantity) {
        newOrderStatus = "shipped";
      } else {
        newOrderStatus = "shipped 1/2";
      }

      // Update the order status
      await updateOrderStatus(orderId, newOrderStatus);

      showSuccessAlert("Your shipment was successfully created");
      navigate(`/order`, {
        state: {
          orderId: orderId,
          shipmentId,
          centerId,
          studyId,
          centerNumber,
          studyCode,
        },
      });
    } catch (error) {
      showErrorAlert("An error occurred while creating the shipment");
      console.error("An error occurred while creating the order:", error);
      navigate(`/orders/`);
    }
  };

  const materialsTypeMaterial = order.Supplies.filter(
    (supply) => supply.Material.type === "material"
  );
  const materialsTypeSupply = order.Supplies.filter(
    (supply) => supply.Material.type === "supply"
  );
  const materialsTypeReagent = order.Supplies.filter(
    (supply) => supply.Material.type === "reagent"
  );
  const materialsTypeDocuments = order.Supplies.filter(
    (supply) => supply.Material.type === "document"
  );

  const CustomDataGrid = ({ rows, headerName }) => {
    const columns = [
      {
        field: "materialName",
        headerName: headerName,
        flex: 0.2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.Material.name,
      },
      {
        field: "quantity",
        headerName: "Needed Quantity",
        flex: 0.1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "shippingQuantity",
        headerName: "Sent Quantity",
        flex: 0.1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <div>
              {
                (newShipment?.productionsSent[params.row.Material.id] || [])
                  .length
              }
            </div>
          );
        },
      },
      {
        field: "productionsSent",
        headerName: "Selected Productions",
        flex: 0.2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => {
          const productionsSent =
            newShipment?.productionsSent[params.row.Material.id] || [];
          const references = productionsSent.map(
            (production) =>
              `(${production.batchNumber}) ${production.reference}`
          );
          return references.join(", ");
        },
      },
    ];

    if (rows.length === 0) {
      return <div></div>;
    }

    return (
      <DataGrid
        slots={{
          noRowsOverlay: NoRowOverlay,
          loadingOverlay: LinearProgress,
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        pageSizeOptions={[30, 50]}
        disableSelectionOnClick
        hideFooter
        autoHeight
      />
    );
  };

  // fetch order
  React.useEffect(() => {
    store.dispatch(getOrder(orderId)).then(() => setIsLoading(false));
  }, [orderId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "auto" }}>
          <OrderTable
            order={order}
            studyCode={studyCode}
            centerNumber={centerNumber}
          />
          <ReadOnlyTextField
            title="SHIPPING ADDRESS"
            value={filteredAddress}
            multiline
            maxRows={4}
          />

          <Box sx={{ p: 2 }}>
            <div style={{ width: "100%" }}>
              <TextField
                value="MATERIAL ORDER"
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
                fullWidth
              />
              <CustomDataGrid
                rows={materialsTypeMaterial}
                headerName="Material"
              />
              <CustomDataGrid rows={materialsTypeSupply} headerName="Supply" />
              <CustomDataGrid
                rows={materialsTypeReagent}
                headerName="Reagent"
              />
              <CustomDataGrid
                rows={materialsTypeDocuments}
                headerName="Document"
              />
            </div>
          </Box>
        </div>

        <Box sx={{ p: 2 }}>
          <TextField
            value="SHIPPING INFORMATION"
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            fullWidth
          />

          <Grid container spacing={2} style={{ marginTop: "12px" }}>
            <Grid item xs={4}>
              <TextField
                label="Preparation Date"
                type="date"
                name="preparationDate"
                value={newShipment?.preparationDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Sending Date"
                type="date"
                name="sendingDate"
                value={newShipment?.sendingDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Carrier Order Date"
                type="date"
                name="carrierOrderDate"
                value={newShipment?.carrierOrderDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Carrier Name"
                fullWidth
                name="carrier"
                value={newShipment?.carrier}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Airwaybill Number"
                name="packingSlip"
                value={newShipment?.packingSlip}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Number of Packages"
                type="number"
                name="numPackages"
                value={newShipment?.numPackages}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "8px" }}
          >
            {Array.from({ length: newShipment?.numPackages }, (_, index) => (
              <React.Fragment key={index}>
                <Grid item xs={3}>
                  <Typography variant="body1">Package {index + 1}:</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Length"
                    type="number"
                    value={newShipment?.packages[index]?.length || ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Height"
                    type="number"
                    value={newShipment?.packages[index]?.height || ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Width"
                    type="number"
                    value={newShipment?.packages[index]?.width || ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Volumetric Weight"
                    value={
                      (newShipment?.packages[index] &&
                        newShipment?.packages[index].volumetricWeight) ||
                      0
                    }
                    type="number"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">kg</InputAdornment>
                      ),
                    }}
                    fullWidth
                    disabled
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>

          {newShipment?.comment && (
            <TextField
              id="comment"
              name="comment"
              label="Comment"
              multiline
              rows={3}
              fullWidth
              sx={{ m: 2 }}
              value={newShipment?.comment}
              disabled
            />
          )}
          <UserInfo
            title={"PERSON IN CHARGE OF SHIPPING"}
            user={user}
            date={new Date().toLocaleDateString()}
          />
        </Box>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={editShipment}
            style={{ padding: "16px 88px", margin: "16px" }}
          >
            Edit shipping information
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleCreateShipment}
            style={{ padding: "16px 88px", margin: "16px" }}
          >
            Confirm shipping information
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};
