import { Grid, Paper, Box, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import Button from "@mui/material/Button";
import * as React from "react";
import { useSelector } from "react-redux";
import { store } from "../../../store";
import { getOrder, editOrder } from "../../../redux/actions/order";
import { useLocation, useNavigate } from "react-router-dom";
import OrderTable from "../../ui/OrderTable";
import Swal from "sweetalert2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ReadOnlyTextField from "../../ui/ReadOnlyTextField";
import UserInfo from "../../ui/UserInfo";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { fetchOrder, updateOrderComment, updateOrderStatus } from "../../../services/orderService";
import { showSuccessAlert, showWarningAlert } from "../../ui/utils/AlertUtils";

export const ScreenOrderInfo = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderId = location.state?.orderId;
  const studyId = location.state?.studyId;
  const order = useSelector((state) => state.order.order);
  const user = useSelector((state) => state.user.user);
  const adminUser = user.role === "admin";
  const cdlUser = user.organization === "CDL";
  const centerUser = user.organization === "Center";
  const [isLoading, setIsLoading] = React.useState(true);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [quantity, setQuantity] = React.useState(0);
  const centerNumber = order?.Center?.centerNumber;
  const studyCode = cdlUser
    ? order?.Center?.Study?.cdlStudyCode
    : order?.Center?.Study?.sponsorStudyCode;
  // eslint-disable-next-line
  const sponsorStudyCode = order?.Center?.Study?.sponsorStudyCode;
  // eslint-disable-next-line
  const centerId = location.state?.centerId || order.centerId;
  const [comment, setComment] = React.useState("");
  const addressParts = [
    order?.hospital,
    order?.ward,
    order?.building ? `Building: ${order.building}` : null,
    order?.street,
    order?.floor ? `Floor: ${order.floor}` : null,
    order?.zipcode,
    order?.shippingAddress,
  ];
  const filteredAddress = addressParts
    .filter((part) => part && part.trim() !== "")
    .join(", ");

  const cancelOrder = async () => {
    try {
      const result = await Swal.fire({
        title: "You are about to cancel this order. Are you sure?",
        text: "Enter the reason for the cancellation of the order.",
        icon: "warning",
        input: "text",
        inputPlaceholder: "Enter your comment here",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel the order",
        confirmButtonColor: "#1e3179",
        cancelButtonText: "No, keep it",
        inputValidator: (value) => {
          if (!value) {
            return "You need to specify a reason for the cancellation";
          }
        },
      });

      if (result.isConfirmed) {
        const comment = result.value;

        // Fetch the current order data
        const orderData = await fetchOrder(orderId);
        const existingComment = orderData.comment || ""; // Get the existing comment
        const newComment = existingComment + " " + comment; // Append the new comment to the existing one

        // Update the order
        await updateOrderStatus(orderId, "cancelled", newComment);
        showSuccessAlert("The order was successfully cancelled").then(() => {
          window.location.reload();
        });
      } else {
        console.error("Failed to update order");
      }
    } catch (error) {
      console.error("An error occurred while updating the order:", error);
    }
  };

  const handleShipment = () => {
    navigate("/shipping/new", {
      state: { order, orderId, centerNumber, studyCode, studyId, centerId },
    });
  };

  const handleAOR = () => {
    navigate("/aor-form", {
      state: {
        orderId,
        order,
        centerNumber,
        studyCode,
        shipmentIndex: 0,
        centerId,
        studyId,
      },
    });
  };

  const getStatusMessage = (status) => {
    switch (status) {
      case "placed":
        return "CDL has been notified of your order and will confirm it as soon as possible.";
      case "confirmed":
        return "Your order has been confirmed and will be sent shortly.";
      case "shipped":
        return "Your order has been shipped. Please allow 3 working days for delivery.";
      case "delivered":
        return "Your order has been delivered. Please confirm reception";
      default:
        return null;
    }
  };
  const handleCellDoubleClick = (params) => {
    if (adminUser) {
      setSelectedRow(params.row);
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  const handleSaveEditQuantity = async () => {
    const body = {
      quantity: Number(quantity),
    };
    store.dispatch(editOrder(selectedRow.id, body));
    await showSuccessAlert("The quantity was successfully updated");
    handleDialogClose();
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const materialsTypeMaterial = order?.Supplies?.filter(
    (supply) => supply.Material.type === "material"
  );
  const materialsTypeSupply = order?.Supplies?.filter(
    (supply) => supply.Material.type === "supply"
  );
  const materialsTypeReagent = order?.Supplies?.filter(
    (supply) => supply.Material.type === "reagent"
  );
  const materialsTypeDocuments = order?.Supplies?.filter(
    (supply) => supply.Material.type === "document"
  );

  const CustomDataGrid = ({ rows = [], headerName }) => {
    const columns = [
      {
        field: "materialName",
        headerName: headerName,
        flex: 0.8,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.Material.name,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        flex: 0.2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
    ];

    if (rows?.length === 0) {
      return <div></div>;
    }

    return (
      <>
        <DataGrid
          slots={{
            noRowsOverlay: NoRowOverlay,
            loadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 30 },
            },
          }}
          disableRowSelectionOnClick
          pageSizeOptions={[30, 50]}
          rowHeight={40}
          hideFooter
          autoHeight
          onCellDoubleClick={handleCellDoubleClick}
        />
      </>
    );
  };

  const confirmOrder = async () => {
    showWarningAlert(
      "This action will confirm the order and notify the center."
    ).then(async (result) => {
      if (result.isConfirmed) {
        const confirmationDate = new Date();
        const confirmedById = user.id;

        try {
          await updateOrderStatus(
            orderId,
            "confirmed",
            confirmedById,
            confirmationDate
          );
          showSuccessAlert("The order was successfully confirmed").then(() => {
            window.location.reload();
          });
        } catch (error) {
          console.error("An error occurred while updating the order:", error);
        }
      }
    });
  };

  React.useEffect(() => {
    store.dispatch(getOrder(orderId)).then(() => setIsLoading(false));
  }, [orderId]);

  React.useEffect(() => {
    // Initialize the comment state when the component mounts
    if (order && order.comment) {
      setComment(order.comment);
    } else {
      setComment('');
    }
  }, [order]);

  React.useEffect(() => {
    if (selectedRow) {
      setQuantity(selectedRow.quantity);
    }
  }, [selectedRow]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "auto" }}>
          <OrderTable
            order={order}
            studyCode={studyCode}
            centerNumber={centerNumber}
            centerId={order.centerId}
            cdlUser={cdlUser}
            studyId={studyId}
          />
          {centerUser && getStatusMessage(order.status) && (
            <div
              style={{
                color: "#0c5460",
                backgroundColor: "#d1ecf1",
                borderColor: "#bee5eb",
                padding: "10px",
                margin: "15px",
                border: "1px solid transparent",
                borderRadius: "4px",
                textAlign: "center",
              }}
            >
              {getStatusMessage(order.status)}
            </div>
          )}

          {centerUser && order.status === "delivered" && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              style={{
                padding: "16px 88px",
                marginTop: "1rem",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onClick={handleAOR}
            >
              Confirm reception
            </Button>
          )}

          {order.status === "cancelled" && (
            <div
              style={{
                padding: "15px",
                margin: "20px",
                border: "1px solid transparent",
                borderRadius: "4px",
                color: "#a94442",
                backgroundColor: "#f2dede",
                borderColor: "#ebccd1",
                textAlign: "center",
              }}
            >
              This order was cancelled
            </div>
          )}

          {cdlUser &&
            order.status !== "placed" &&
            order.status !== "cancelled" &&
            order.shipments &&
            order.shipments.reduce(
              (sum, shipment) => sum + shipment.shippedQuantity,
              0
            ) < order.totalQuantity && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                style={{
                  padding: "16px 88px",
                  marginTop: "1rem",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={handleShipment}
              >
                {order.status === "confirmed"
                  ? "Add shipping information"
                  : "Add a new shipment"}
              </Button>
            )}

          <ReadOnlyTextField
            title="SHIPPING ADDRESS"
            value={filteredAddress}
            multiline
            maxRows={4}
          />

          <Box sx={{ p: 2 }}>
            <div style={{ width: "100%" }}>
              <TextField
                value="MATERIAL ORDER"
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
                fullWidth
              />
              <CustomDataGrid
                rows={materialsTypeMaterial}
                headerName="Material"
              />
              <CustomDataGrid rows={materialsTypeSupply} headerName="Supply" />
              <CustomDataGrid
                rows={materialsTypeReagent}
                headerName="Reagent"
              />
              <CustomDataGrid
                rows={materialsTypeDocuments}
                headerName="Document"
              />
            </div>
          </Box>
          <Box sx={{ px: 2 }}>
            <FormControlLabel
              control={
                <Checkbox checked={order.isFirstSupply} name="isFirstSupply" />
              }
              label="First supply"
              disabled
            />
            <FormControlLabel
              control={<Checkbox checked={order.isDemokit} name="isDemoKit" />}
              label="Demo kit"
              disabled
            />
          </Box>

          {cdlUser && (
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                px: 2,
              }}
            >
              <TextField
                id="comment"
                name="comment"
                label="Comment"
                multiline
                rows={3}
                sx={{ mr: 2, width: "70%" }}
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
              />
              {adminUser && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "fit-content",
                    width: "30%",
                    padding: "16px",
                  }}
                  onClick={async () => {
                    await updateOrderComment(orderId, comment);
                    showSuccessAlert("The comment was successfully updated");
                  }}
                >
                  Update comment
                </Button>
              )}
            </Box>
          )}
          {selectedRow && (
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
              <DialogTitle>Edit Quantity</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Quantity"
                  type="number"
                  value={quantity}
                  onChange={(event) => setQuantity(event.target.value)}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={handleDialogClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ m: 1, padding: "8px 16px" }}
                  onClick={handleSaveEditQuantity}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Box sx={{ p: 2 }}>
            <UserInfo
              title="PERSON WHO PLACED THIS ORDER"
              user={order?.placedBy}
              date={formatDate(order?.orderDate)}
            />
            {order?.confirmedBy && (
              <UserInfo
                title="PERSON WHO CONFIRMED THIS ORDER"
                user={order?.confirmedBy}
                date={
                  order?.confirmationDate
                    ? formatDate(order?.confirmationDate)
                    : null
                }
              />
            )}
            {order.shipments &&
              order.shipments[0]?.confirmedDeliveryDate &&
              !cdlUser && (
                <UserInfo
                  title="PERSON IN CHARGE OF RECEPTION"
                  user={order.shipments[0].receivedBy}
                  date={formatDate(order.shipments[0]?.confirmedDeliveryDate)}
                />
              )}
            {adminUser && order.status === "placed" && (
              <Grid
                container
                item
                xs={12}
                justifyContent={"space-between"}
                sx={{ mt: 2, mb: 2 }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  style={{
                    padding: "16px",
                    marginBottom: "4px",
                    width: "350px",
                  }}
                  onClick={cancelOrder}
                >
                  CANCEL ORDER
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    padding: "16px",
                    marginBottom: "4px",
                    width: "350px",
                  }}
                  onClick={() => {
                    console.log("centerId", centerId);
                  }}
                >
                  EDIT ORDER
                </Button> */}

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  style={{
                    padding: "16px",
                    marginBottom: "4px",
                    width: "350px",
                  }}
                  onClick={confirmOrder}
                >
                  Confirm Order
                </Button>
              </Grid>
            )}
          </Box>
        </div>
      </Paper>
    </Grid>
  );
};
