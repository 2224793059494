import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { getCenterUsers } from "../../../redux/actions/user";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import * as React from "react";
import {
  getCenter,
  updateCenter,
  deleteCenter,
} from "../../../redux/actions/center";
import { store } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getProductionsByCenter } from "../../../redux/actions/production";
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../ui/utils/AlertUtils";

const columns = [
  { field: "name", headerName: "Name", flex: 1 },
  { field: "firstname", headerName: "First name", flex: 1 },
  { field: "email", headerName: "Mail", flex: 1 },
  { field: "phoneNumber", headerName: "Phone", flex: 1 },
];

export const ScreenCenterInfo = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const centerId = location.state?.centerId;
  // eslint-disable-next-line
  const studyId = location.state?.studyId;
  const center = useSelector((state) => state.center.center);
  const employees = useSelector((state) => state.user?.employees);
  const monitors = useSelector((state) => state.user?.monitors);
  const isLoading = useSelector((state) => state.user.isLoadingUsers);
  const protocols = center?.Protocols;
  const [centerStock, setCenterStock] = React.useState([]);
  const [status, setStatus] = React.useState(center?.status || "");
  const [officePhone, setOfficePhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(false);
  const [contactName, setContactName] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const firstName = contactName.split(",")[1] || "";
  const lastName = contactName.split(",")[0] || "";
  const [changesMade, setChangesMade] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [hospital, setHospital] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [ward, setWard] = React.useState("");
  const [building, setBuilding] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");
  const [floor, setFloor] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");

  React.useEffect(() => {
    setHospital(center?.hospital || "");
    setWard(center?.ward || "");
    setBuilding(center?.building || "");
    setStreet(center?.street || "");
    setFloor(center?.floor || "");
    setZipcode(center?.zipcode || "");
    setCity(center?.city || "");
    setCountry(center?.country || "");
  }, [center]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAddress = async () => {
    const updatedCenter = {
      hospital: hospital,
      ward: ward,
      building: building,
      street: street,
      floor: floor,
      zipcode: zipcode,
      city: city,
      country: country,
    };
    await store.dispatch(updateCenter(centerId, updatedCenter));

    setOpen(false);
    showSuccessAlert("Your changes have been saved.");
  };

  const formatAddress = (center) => {
    if (!center) return "";

    return [
      center.hospital,
      center.ward,
      center.building,
      center.street,
      center.floor,
      center.zipcode,
      center.city,
      center.country,
    ]
      .filter(Boolean)
      .join(", ");
  };

  const handleOfficePhoneChange = (e) => {
    const phoneRegex = /^(\+|00)?\d{1,3}[- ]?(\d{1,14}|\d{1,14}( \d{1,14})*)$/;
    const isValidPhone = phoneRegex.test(e.target.value);

    setOfficePhone(e.target.value);
    setPhoneError(!isValidPhone);

    if (isValidPhone) {
      setChangesMade(true);
    }
  };

  const saveChanges = () => {
    const updatedCenter = {
      ...center,
      officePhone: officePhone,
      status: status,
      contactName: contactName,
      contactPhone: contactPhone,
      contactEmail: contactEmail,
    };
    store.dispatch(updateCenter(centerId, updatedCenter));
    setChangesMade(false);
    showSuccessAlert("Your changes have been saved.");
  };

  const clearChanges = () => {
    // Reset the state variables to their initial values
    setStatus(center?.status || "");
    setOfficePhone(center?.officePhone || "");
    setContactName(center?.contactName || "");
    setContactPhone(center?.contactPhone || "");
    setContactEmail(center?.contactEmail || "");
    setChangesMade(false);
  };

  const handleDeleteCenter = async () => {
    try {
      const result = await showWarningAlert(
        "Do you really want to delete this center? This action is irreversible."
      );
      if (result.isConfirmed) {
        await store.dispatch(deleteCenter(centerId));
        showSuccessAlert("Center deleted successfully");
        navigate("/centers");
      }
    } catch (error) {
      console.error(error);
      showErrorAlert("Failed to delete center");
    }
  };

  // get productions (if stock is empty center can be deleted)
  React.useEffect(() => {
    const fetchProductions = async () => {
      const productions = await store.dispatch(
        getProductionsByCenter(centerId)
      );
      setCenterStock(productions);
    };

    fetchProductions();
  }, [centerId]);

  React.useEffect(() => {
    store.dispatch(getCenter(centerId));
    store.dispatch(getCenterUsers(centerId));
  }, [centerId]);

  React.useEffect(() => {
    setStatus(center?.status || "");
    setOfficePhone(center?.officePhone || "");
    setContactName(center?.contactName || "");
    setContactPhone(center?.contactPhone || "");
    setContactEmail(center?.contactEmail || "");
  }, [center]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
        }}
      >
        <div style={{ width: "100%" }}>
          <Box m={4}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Center Number"
                  value={center?.centerNumber || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    label="Status"
                    value={status}
                    onChange={(event) => {
                      setStatus(event.target.value);
                      setChangesMade(true);
                    }}
                  >
                    <MenuItem value={"active"}>active</MenuItem>
                    <MenuItem value={"closed"}>closed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Office Phone"
                  value={officePhone}
                  onChange={handleOfficePhoneChange}
                  error={phoneError}
                  helperText={phoneError ? "Invalid phone number" : ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          {(!center?.hospital || !center?.street || !center?.zipcode) && (
            <Box
              m={4}
              style={{
                backgroundColor: "#fcf8e3",
                padding: "15px",
                borderRadius: "4px",
              }}
            >
              <Typography
                variant="body1"
                align="center"
                style={{ color: "#8a6d3b" }}
              >
                The full address is missing. Enter it below :
              </Typography>
            </Box>
          )}
          <Box m={4}>
            <TextField
              label="Address"
              value={formatAddress(center)}
              onDoubleClick={handleClickOpen}
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              PaperProps={{
                style: { padding: "20px" },
              }}
            >
              <DialogTitle id="form-dialog-title">
                Update Center Address
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Hospital"
                  value={hospital}
                  fullWidth
                  onChange={(e) => setHospital(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Ward"
                  value={ward}
                  fullWidth
                  onChange={(e) => setWard(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Building"
                  value={building}
                  fullWidth
                  onChange={(e) => setBuilding(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Street"
                  value={street}
                  fullWidth
                  onChange={(e) => setStreet(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Floor"
                  value={floor}
                  fullWidth
                  onChange={(e) => setFloor(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Zipcode"
                  value={zipcode}
                  fullWidth
                  onChange={(e) => setZipcode(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="City"
                  value={city}
                  fullWidth
                  onChange={(e) => setCity(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Country"
                  value={country}
                  fullWidth
                  onChange={(e) => setCountry(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveAddress}
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Box>

          <Box m={4}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="Contact First Name"
                  value={firstName}
                  onChange={(e) => {
                    setContactName(`${lastName}, ${e.target.value.trim()}`);
                    setChangesMade(true);
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Contact Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setContactName(
                      `${e.target.value.toUpperCase()},${firstName}`
                    );
                    setChangesMade(true);
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Contact Phone"
                  value={contactPhone}
                  onChange={(e) => {
                    setContactPhone(e.target.value);
                    setChangesMade(true);
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Contact Email"
                  value={contactEmail}
                  onChange={(e) => {
                    setContactEmail(e.target.value);
                    setChangesMade(true);
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          {changesMade && (
            <Box m={4}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ padding: "16px 8px", flex: 0.3, margin: "4px " }}
                  onClick={clearChanges}
                >
                  Cancel changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ padding: "16px 8px", flex: 0.3, margin: "4px" }}
                  onClick={saveChanges}
                >
                  Update status
                </Button>
              </div>
            </Box>
          )}
          <Box mx={4} mt={8}>
            <TextField
              value="CENTER EMPLOYEES"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            {employees && employees?.length > 0 ? (
              <DataGrid
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={employees}
                columns={columns}
                loading={isLoading}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20]}
                checkboxSelection
                hideFooter
                rowHeight={40}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                There are no center employees for this center yet
              </div>
            )}
          </Box>

          <Box m={4}>
            <TextField
              value="MONITORS"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            {monitors && monitors?.length > 0 ? (
              <DataGrid
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={monitors}
                columns={columns}
                loading={isLoading}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20]}
                checkboxSelection
                hideFooter
                rowHeight={40}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                There are no monitors for this center yet
              </div>
            )}
          </Box>
          <Box m={4}>
            {protocols && protocols?.length > 0 ? (
              <DataGrid
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={protocols}
                columns={[
                  {
                    field: "reference",
                    headerName: "CURRENT PROTOCOLS",
                    flex: 1,
                    headerAlign: "center",
                    align: "center",
                    sortable: false,
                    disableColumnMenu: true,
                  },
                ]}
                rowHeight={40}
                loading={isLoading}
                pageSizeOptions={[10, 20]}
                hideFooter
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                This center is on the initial protocol of the study
              </div>
            )}
          </Box>
        </div>
        {employees?.length === 0 && centerStock?.length === 0 ? (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleDeleteCenter}
          >
            Delete the center
          </Button>
        ) : null}
      </Paper>
    </Grid>
  );
};
